import React, { useState, useEffect } from "react";
import NavigationBar from "../components/NavBar";
import "../components/Hero.css";
import "../components/Services.css";
import "../components/Why-choose.css";
import "../components/FAQs.css";
import "../components/Contact.css";
import mobile_icon from "../assets/images/mobile_icon.png";
import web_icon from "../assets/images/web_icon.png";
import contact_image from "../assets/images/contact_image.png";
import heroBg from "../assets/images/heroBg.jpg";

import { database } from "../lib/firebase";
import { ref, set, push } from "firebase/database";
import faqService from "../lib/getFAQ";

import {
  FaEnvelope,
  FaCommentDots,
  FaStar,
  FaArrowDown,
} from "react-icons/fa";
import BottomBar from "../components/BottomBar";


export default function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [faqs, setFaqs] = useState([]); // Initialize state for FAQs

  // Fetch FAQs from service
  useEffect(() => {
    async function fetchFAQ() {
      const fetchedFaqs = await faqService.getHomeFAQ();
      setFaqs(fetchedFaqs || []); // Set the fetched FAQs in state
    }
    fetchFAQ();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
    const messageRef = push(ref(database, 'messages'));
    set(messageRef, { ...formData, timestamp: new Date().toISOString() });
    // Reset form
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };



  return (
    <div className="main-body">
      <NavigationBar position_absolute={true} />

      {/* Hero Section */}
      <div className="back-container">
        <div className="Hero-contaner">
          <div className="Hero-image">
            <img className="hero-img" src={heroBg} alt="contact-image" />
          </div>
          <div className="Hero-text-cont">
            <div className="Hero-text">
              Your Vision Our Code
            </div>
            <div className="Hero-text-desc">
              Anemo Studio creates custom mobile apps and websites, combining creativity and technology to bring your ideas to life.
            </div>
            <div className="btn-sec" style={{
              width: '100%',
              marginTop: '24px',
            }}>
              <a href="#service-sec" className="btn-txt">Get Started</a>
            </div>
          </div>

          <div className="scroll-down">
            Scroll Down
            <FaArrowDown className="scroll-arrow" />
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="why-choose-contaner">
          <div className="left-container-choose">
            <div className="text-choose">Why Choose Us?</div>
          </div>
          <div className="right-container-choose">
            <div className="answer-choose">
              We’re a dynamic digital agency that merges your business objectives
              with our innovative design and technical expertise, bringing your
              vision to life across websites, mobile applications, and video
              content. From startups to established brands, we craft digital
              experiences that captivate and engage your audience.
              <br />
              <br />
              Our diverse team of web developers, mobile app creators, video
              editors, and online marketing specialists works in harmony to
              deliver projects that resonate. We manage every aspect of your
              digital needs, ensuring seamless execution and impactful
              storytelling.
              <br />
              <br />
              Consider us your digital dream team, dedicated to enhancing your
              online presence and driving engagement through cutting-edge
              technology and creative solutions.
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div id="service-sec" className="top-text">Services</div>

        <div className="service-contaner">
          <div className="service-left">
            <div className="service-app">
              <a href="/app-services">
                <div className="service-icon">
                  <img src={mobile_icon} alt="Mobile App Development" />
                </div>
                <h3>App Development</h3>
              </a>
            </div>
            <div className="service-web">
              <a href="/web-services">
                <div className="service-icon">
                  <img src={web_icon} alt="Website Development" />
                </div>
                <h3>Website Development</h3>
              </a>
            </div>
          </div>

          <div className="service-right">
            <div className="service-text">
              <h2>Services We Provide</h2>
              <p>
                At <b>Anemo Studio</b>, we offer expert mobile app and website development services, delivering custom, high-performance digital solutions tailored to your business needs. From sleek UI/UX design to powerful e-commerce platforms, we ensure your online presence is both engaging and effective.
              </p>
            </div>

            <div className="how-it-works">
              <h2>Let's See How it Works</h2>
              <div className="steps">
                <div className="step">
                  <FaEnvelope className="icon" />
                  <h3>Contact us via email</h3>
                  <p>
                    Email in the way of your introduction and the service you
                    needed.
                  </p>
                </div>
                <div className="step">
                  <FaCommentDots className="icon" />
                  <h3>Explain your needs to us</h3>
                  <p>
                    By analysing your needs we will find the best way possible to
                    serve you.
                  </p>
                </div>
                <div className="step">
                  <FaStar className="icon" />
                  <h3>Leave afterwards to us</h3>
                  <p>
                    After analysing your needs, our team will contact you and
                    explain you the possible solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="faq-section">
          <div className="faq-top-text">FAQs</div>
          <div className="faq-list">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`faq-item ${selectedQuestion === index ? "active" : ""
                  }`}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleQuestion(index)}
                >
                  {faq.question}
                </div>
                <div className="faq-answer">
                  {selectedQuestion === index && faq.answer}
                </div>
              </div>
            ))}
          </div>
          <div className="faq-bottom-text">Still have questions?</div>
          <div className="arrow-down-centered">
            <FaArrowDown className="scroll-arrow" />
          </div>
        </div>

        {/* Contact Section */}
        <div className="contact-top-text" id="start-contact-section">
          Contact Us
        </div>
        <div className="contact-content">
          <div className="contact-section">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button className="form-btn" type="submit">
                Submit
              </button>
            </form>
          </div>
          <div className="contact-image">
            <img src={contact_image} alt="Contact-Image" />
          </div>
        </div>

        {/* Contact Section */}
        <section style={{ textAlign: 'center', padding: '20px', backgroundColor: '#333', color: '#fff', }}>
          <h3 style={{ ffontSize: '2rem', color: '#fff', marginBottom: '20px' }}>Contact Us</h3>
          <p style={{ fontSize: '1rem', color: '#ddd', marginBottom: '20px', maxWidth: '600px', margin: '0 auto' }}>
            Have any questions or feedback? We’d love to hear from you! Feel free to reach out via email or follow us on social media.
          </p>
          <a href="mailto:contact.anemostudio@gmail.com" style={{ color: '#ec008c', textDecoration: 'none', fontSize: '1.1rem', marginBottom: '20px', display: 'block' }}>
            contact.anemostudio@gmail.com
          </a>
          <p>Phone No.: +91 7710198782</p>

          <p style={{ fontSize: '1rem', color: '#ddd', lineHeight: '1.6' }}>
            Follow us on social media:
            <a href="https://www.instagram.com/anemo_studio_/" style={{ margin: '0 5px', color: '#007acc', textDecoration: 'none' }}>Instagram</a> |
            <a href="https://x.com/Anemo_Studio" style={{ margin: '0 5px', color: '#007acc', textDecoration: 'none' }}>Twitter</a> |
            <a href="https://www.linkedin.com/company/anemo-studios/posts/?feedView=all" style={{ margin: '0 5px', color: '#007acc', textDecoration: 'none' }}>LinkedIn</a>
          </p>
        </section>

        <BottomBar />

      </div>
    </div>
  );
}
