import React from 'react';
import NavigationBar from '../components/NavBar';
import BottomBar from '../components/BottomBar';

const TermsAndConditions = () => {
  return (
    <div style={{
        background: '#fff',
    }}>
        <NavigationBar position_absolute={false}/>
        <div style={{padding: "20px"}}>
      <h1>Terms and Conditions</h1>
      <p>Welcome to Anemo Studio!</p>
      <p>
        These Terms and Conditions govern your access and use of the services provided by Anemo Studio. By accessing 
        or using our services, you agree to comply with and be bound by these terms. If you disagree with any part of 
        the terms, you must not use our services.
      </p>
      <h2>1. Use of Our Services</h2>
      <p>
        Our services are designed to provide high-quality mobile applications and websites. You are required to use 
        these services lawfully and responsibly. Any unauthorized use, including hacking, misuse, or fraudulent 
        activity, is strictly prohibited.
      </p>
      <h2>2. Intellectual Property</h2>
      <p>
        All content, designs, and trademarks used on our website are the property of Anemo Studio. You may not 
        reproduce, distribute, or create derivative works without prior written permission.
      </p>
      <h2>3. User Responsibilities</h2>
      <ul>
        <li>Provide accurate information during project initiation.</li>
        <li>Communicate clearly for revisions and feedback.</li>
        <li>Ensure timely payment for services rendered.</li>
      </ul>
      <h2>4. Payment and Pricing</h2>
      <p>
        All payments must be made in INR. Pricing will be detailed in the project agreement. Anemo Studio reserves the 
        right to modify pricing at its discretion for new services or packages.
      </p>
      <h2>5. Cancellation Policy</h2>
      <p>
        Cancellation of projects is permitted within 7 days of the initial agreement. For cancellations, please contact 
        us via email at <a href="mailto:contact.anemostudio@gmail.com">contact.anemostudio@gmail.com</a>.
      </p>
      <h2>6. Limitation of Liability</h2>
      <p>
        Anemo Studio will not be held responsible for any indirect, incidental, or consequential damages arising from 
        the use or inability to use our services.
      </p>
      <h2>7. Amendments to Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. It is your responsibility to review these terms 
        periodically for any changes.
      </p>
      <h2>8. Governing Law</h2>
      <p>
        These terms are governed by the laws of India. Any disputes arising will be subject to the exclusive 
        jurisdiction of the courts of India.
      </p>
      <h2>Contact Us</h2>
      <p>Email: contact.anemostudio@gmail.com</p>
      <p>Phone: +91 7710198782</p>
      </div>
      <BottomBar/>
    </div>
  );
};

export default TermsAndConditions;
