import React from 'react';

const GoogleForm = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h2>Fill Out the Form Below</h2>
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSdTjZ4H8S4PIhazAXVbKpWBGuEIoAVCF-DRn4RqvJgN2WHv3A/viewform?embedded=true" 
                width="100%" 
                height="1437" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0"
                style={{ border: 'none' }} 
                title="Google Form"
            >
                Loading…
            </iframe>
        </div>
    );
}

export default GoogleForm;
