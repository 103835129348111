import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Home from "./pages/home";
import Product from "./pages/product";
import AboutUs from "./pages/about";
import ApplicationService from "./pages/app";
import WebService from "./pages/web";
import AdminConsole from "./pages/console";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/products" element={<Product />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/app-services" element={<ApplicationService />} />
        <Route path="/web-services" element={<WebService />} />
        <Route path="/admin/console" element={<AdminConsole />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
