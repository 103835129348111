import faqService from "../lib/getFAQ";
import React, { useState, useEffect } from "react";

// Home FAQs Component
function GetHomeFAQs() {
    const [faqs, setFaqs] = useState([]);
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");

    async function fetchFAQ() {
        const fetchedFaqs = await faqService.getHomeFAQ();
        setFaqs(fetchedFaqs || []);
    }

    useEffect(() => {
        fetchFAQ();
    }, []);

    const handleAddFAQ = async () => {
        await faqService.setHomeFAQ(question, answer);
        fetchFAQ();
        setQuestion("");
        setAnswer("");
    };

    const handleDeleteFAQ = async (faqId) => {
        await faqService.deleteHomeFAQ(faqId);
        fetchFAQ();
    };

    return (
        <div style={faqContainerStyle}>
            <h2>Home FAQs</h2>
            {faqs.map((faq, index) => (
                <div key={index} style={faqItemStyle}>
                    <div style={faqQuestionStyle}>Question: {faq.question}</div>
                    <div style={faqAnswerStyle}>Answer: {faq.answer}</div>
                    <button onClick={() => handleDeleteFAQ(faq.id)} style={deleteButtonStyle}>Delete</button>
                </div>
            ))}
            <input type="text" placeholder="Question" value={question} onChange={(e) => setQuestion(e.target.value)} style={inputStyle} />
            <input type="text" placeholder="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} style={inputStyle} />
            <button onClick={handleAddFAQ} style={addButtonStyle}>Add FAQ</button>
        </div>
    );
}

// App FAQs Component
function GetAppFAQs() {
    const [faqs, setFaqs] = useState([]);
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");

    async function fetchFAQ() {
        const fetchedFaqs = await faqService.getAppFAQ();
        setFaqs(fetchedFaqs || []);
    }

    useEffect(() => {
        fetchFAQ();
    }, []);

    const handleAddFAQ = async () => {
        await faqService.setAppFAQ(question, answer);
        fetchFAQ();
        setQuestion("");
        setAnswer("");
    };

    const handleDeleteFAQ = async (faqId) => {
        await faqService.deleteAppFAQ(faqId);
        fetchFAQ();
    };

    return (
        <div style={faqContainerStyle}>
            <h2>App FAQs</h2>
            {faqs.map((faq, index) => (
                <div key={index} style={faqItemStyle}>
                    <div style={faqQuestionStyle}>Question: {faq.question}</div>
                    <div style={faqAnswerStyle}>Answer: {faq.answer}</div>
                    <button onClick={() => handleDeleteFAQ(faq.id)} style={deleteButtonStyle}>Delete</button>
                </div>
            ))}
            <input type="text" placeholder="Question" value={question} onChange={(e) => setQuestion(e.target.value)} style={inputStyle} />
            <input type="text" placeholder="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} style={inputStyle} />
            <button onClick={handleAddFAQ} style={addButtonStyle}>Add FAQ</button>
        </div>
    );
}

// Web FAQs Component
function GetWebFAQs() {
    const [faqs, setFaqs] = useState([]);
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");

    async function fetchFAQ() {
        const fetchedFaqs = await faqService.getWebFAQ();
        setFaqs(fetchedFaqs || []);
    }

    useEffect(() => {
        fetchFAQ();
    }, []);

    const handleAddFAQ = async () => {
        await faqService.setWebFAQ(question, answer);
        fetchFAQ();
        setQuestion("");
        setAnswer("");
    };

    const handleDeleteFAQ = async (faqId) => {
        await faqService.deleteWebFAQ(faqId);
        fetchFAQ();
    };

    return (
        <div style={faqContainerStyle}>
            <h2>Web FAQs</h2>
            {faqs.map((faq, index) => (
                <div key={index} style={faqItemStyle}>
                    <div style={faqQuestionStyle}>Question: {faq.question}</div>
                    <div style={faqAnswerStyle}>Answer: {faq.answer}</div>
                    <button onClick={() => handleDeleteFAQ(faq.id)} style={deleteButtonStyle}>Delete</button>
                </div>
            ))}
            <input type="text" placeholder="Question" value={question} onChange={(e) => setQuestion(e.target.value)} style={inputStyle} />
            <input type="text" placeholder="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} style={inputStyle} />
            <button onClick={handleAddFAQ} style={addButtonStyle}>Add FAQ</button>
        </div>
    );
}

// CSS Styles
const faqContainerStyle = {
    width: '90%',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif'
};

const faqItemStyle = {
    marginBottom: '15px',
    padding: '15px',
    borderLeft: '4px solid #4caf50',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    position: 'relative'
};

const faqQuestionStyle = {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#4caf50'
};

const faqAnswerStyle = {
    fontSize: '16px',
    color: '#333'
};

const deleteButtonStyle = {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    position: 'absolute',
    right: '10px',
    top: '10px'
};

const inputStyle = {
    width: 'calc(100% - 20px)',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px'
};

const addButtonStyle = {
    backgroundColor: '#4caf50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    transition: 'background-color 0.3s ease'
};

export { GetHomeFAQs, GetAppFAQs, GetWebFAQs };
