import { database } from './firebase';
import { ref, get, remove } from 'firebase/database';

// Function to fetch orders from Firebase
async function getOrders() {
    try {
        const ordersRef = ref(database, 'Orders');
        const snapshot = await get(ordersRef);
        
        if (snapshot.exists()) {
            const data = snapshot.val();
            const ordersArray = Object.keys(data).map((key) => ({
                id: key, 
                ...data[key], 
            }));
            return ordersArray; 
        } else {
            console.log('No orders data available');
            return [];
        }
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw new Error('Failed to fetch orders');
    }
}

// Function to delete an order from Firebase
async function deleteOrder(orderId) {
    try {
        const orderRef = ref(database, `Orders/${orderId}`);
        await remove(orderRef);
        console.log(`Order with ID: ${orderId} deleted successfully.`);
    } catch (error) {
        console.error('Error deleting order:', error);
        throw new Error('Failed to delete order');
    }
}

const orderService = {
    getOrders,
    deleteOrder, 
};

export default orderService;
