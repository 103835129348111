import './Navbar.css';
import React, { useState } from 'react';
import logo from '../assets/images/Logo2.png'

export default function NavigationBar({ position_absolute }) {
    const [showMenu, setShowMenu] = useState(false); 

    const checkInnerWidth = () => {
        if (window.innerWidth <= 768){
            return true;
        }
        return false;
    }

    const low = checkInnerWidth()
    const high = position_absolute;

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={`nav${high ? '-absolute' : ''}`}>
            <div className="left-nav" onClick={()=> {
                window.location.href = '/';
            }}
            
            style={{
                cursor: 'pointer',
            }}>
                <img className='logo' src={logo} alt="logo" />
                <div className="nav-name">AnemoStudio</div>
            </div>
            {/* Sandwich button for small screens */}
            <button className="sandwich-button" onClick={toggleMenu}>
                ☰
            </button>
            <div className={`right-nav ${showMenu ? 'show' : ''}`}>
                <a href='/' className={`view${ low ? '-Yup' :''}`}>Home</a>
                <a href='/app-services'>Mobile Apps</a>
                <a href='/web-services'>Websites</a>
                {/* <a href='/products'>Products</a> */}
                {/* <a href='/about-us'>AboutUs</a> */}
            </div>
        </div>
    );
}