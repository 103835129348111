import React from 'react';
import NavigationBar from '../components/NavBar';

const Product = () => {
  return (
    <div>
      <NavigationBar position_absolute={true} />
      <div style={{
        // backgroundImage: "url('/path-to-your-background-image.jpg')",
        backgroundColor: "#253745",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}>
        <div style={{
          maxWidth: '800px',
          padding: '20px',
        }}>
          <h1 style={{
            fontSize: '3rem',
            marginBottom: '20px',
          }}>Your Product Name</h1>
          <p style={{
            fontSize: '1.2rem',
            marginBottom: '30px',
          }}>
            Revolutionize your experience with cutting-edge features and a sleek design.
          </p>
          <button style={{
            padding: '15px 30px',
            fontSize: '1rem',
            backgroundColor: '#ff6600',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }} 
          onMouseEnter={(e) => e.target.style.backgroundColor = '#ff4500'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#ff6600'}>
            Get Started
          </button>
        </div>
      </div>

      {/* Product section */}
      
    </div>
  );
};

export default Product;
