import { database } from './firebase';
import { ref, get, set, remove } from 'firebase/database';

// Get Home FAQs
async function getHomeFAQ() {
  try {
    const faqRef = ref(database, 'homeFAQ');
    const snapshot = await get(faqRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const faqArray = Object.keys(data).map((key) => ({
        id: key, 
        question: key,
        answer: data[key].answer
      }));
      return faqArray;
    } else {
      console.log('No FAQ data available');
      return [];
    }
  } catch (error) {
    console.error('Error fetching FAQ:', error);
  }
}

// Set Home FAQ
async function setHomeFAQ(question, answer) {
  try {
    const newFaqRef = ref(database, `homeFAQ/${question}`);
    await set(newFaqRef, { question, answer });
    console.log('FAQ set successfully');
  } catch (error) {
    console.error('Error setting FAQ:', error);
  }
}

// Delete Home FAQ
async function deleteHomeFAQ(faqId) {
  try {
    const faqRef = ref(database, `homeFAQ/${faqId}`);
    await remove(faqRef); // Use `remove` to delete the FAQ
    console.log('FAQ deleted successfully');
  } catch (error) {
    console.error('Error deleting FAQ:', error);
  }
}

// Get App FAQs
async function getAppFAQ() {
  try {
    const faqRef = ref(database, 'appFAQ');
    const snapshot = await get(faqRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const faqArray = Object.keys(data).map((key) => ({
        id: key,
        question: key,
        answer: data[key].answer
      }));
      return faqArray;
    } else {
      console.log('No FAQ data available');
      return [];
    }
  } catch (error) {
    console.error('Error fetching FAQ:', error);
  }
}

// Set App FAQ
async function setAppFAQ(question, answer) {
  try {
    const newFaqRef = ref(database, `appFAQ/${question}`);
    await set(newFaqRef, { question, answer });
    console.log('FAQ set successfully');
  } catch (error) {
    console.error('Error setting FAQ:', error);
  }
}

// Delete App FAQ
async function deleteAppFAQ(faqId) {
  try {
    const faqRef = ref(database, `appFAQ/${faqId}`);
    await remove(faqRef);
    console.log('FAQ deleted successfully');
  } catch (error) {
    console.error('Error deleting FAQ:', error);
  }
}

// Get Web FAQs
async function getWebFAQ() {
  try {
    const faqRef = ref(database, 'webFAQ');
    const snapshot = await get(faqRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const faqArray = Object.keys(data).map((key) => ({
        id: key,
        question: key,
        answer: data[key].answer
      }));
      return faqArray;
    } else {
      console.log('No FAQ data available');
      return [];
    }
  } catch (error) {
    console.error('Error fetching FAQ:', error);
  }
}

// Set Web FAQ
async function setWebFAQ(question, answer) {
  try {
    const newFaqRef = ref(database, `webFAQ/${question}`);
    await set(newFaqRef, { question, answer });
    console.log('FAQ set successfully');
  } catch (error) {
    console.error('Error setting FAQ:', error);
  }
}

// Delete Web FAQ
async function deleteWebFAQ(faqId) {
  try {
    const faqRef = ref(database, `webFAQ/${faqId}`);
    await remove(faqRef);
    console.log('FAQ deleted successfully');
  } catch (error) {
    console.error('Error deleting FAQ:', error);
  }
}

const faqService = {
  getHomeFAQ,
  setHomeFAQ,
  deleteHomeFAQ, 
  getAppFAQ,
  setAppFAQ,
  deleteAppFAQ, 
  getWebFAQ,
  setWebFAQ,
  deleteWebFAQ,
};

export default faqService;
