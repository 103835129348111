import React from 'react';
import NavigationBar from '../components/NavBar';
import BottomBar from '../components/BottomBar';

const PrivacyPolicy = () => {
  return (
    <div style={{
      background: '#fff',
     }}>
      <NavigationBar position_absolute={false}/>
      <div style={{ padding: '20px',}}>
      <h1>Privacy Policy</h1>
      <p>
        At Anemo Studio, your privacy is of utmost importance to us. This Privacy Policy explains how we collect, 
        use, and protect your personal data.
      </p>
      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>Personal identification information: Name, email address, phone number.</li>
        <li>Payment details for billing purposes.</li>
        <li>Project-specific data for delivering services.</li>
      </ul>
      <h2>2. How We Use Your Data</h2>
      <p>We use your data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our services.</li>
        <li>To process payments and deliver invoices.</li>
        <li>To improve user experience and troubleshoot issues.</li>
        <li>To comply with legal obligations.</li>
      </ul>
      <h2>3. Data Security</h2>
      <p>
        We implement robust security measures to ensure the safety of your personal data. However, no method of 
        transmission over the internet or electronic storage is 100% secure.
      </p>
      <h2>4. Sharing Your Information</h2>
      <p>
        Your personal data will not be shared with third parties except under the following circumstances:
      </p>
      <ul>
        <li>With your explicit consent.</li>
        <li>To comply with legal or regulatory requirements.</li>
        <li>To fulfill contractual obligations to you.</li>
      </ul>
      <h2>5. Refund Policy</h2>
      <p>
        Refunds will be processed within 5-6 working days upon approval. Refund requests must be submitted via email to  
        <a href="mailto:contact.anemostudio@gmail.com"> contact.anemostudio@gmail.com</a>.
      </p>
      <h2>6. Cookies and Tracking</h2>
      <p>
        Our website uses cookies to enhance user experience. You can disable cookies through your browser settings, 
        though this may affect website functionality.
      </p>
      <h2>7. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal data. To exercise these rights, contact us at 
        <a href="mailto:contact.anemostudio@gmail.com"> contact.anemostudio@gmail.com</a>.
      </p>
      <h2>8. Updates to This Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Continued use of our services constitutes acceptance of 
        updated terms.
      </p>
      <h2>Contact Us</h2>
      <p>Email: contact.anemostudio@gmail.com</p>
      <p>Phone: +91 7710198782</p>
      </div>
      <BottomBar />
    </div>
  );
};

export default PrivacyPolicy;
