import React from 'react';
import './BottomBar.css';

const BottomBar = () => {
  return (
    <div className="bottom-bar">
      <a href="/privacy-policy" className="bottom-bar-link">Privacy Policy</a>
      <a href="/terms-and-conditions" className="bottom-bar-link">Terms and Conditions</a>
    </div>
  );
};

export default BottomBar;
