import React, { useState, useEffect } from "react";
import { ref, onValue, update, remove } from "firebase/database";
import { database } from "../lib/firebase";
import './PlanList.css';

// PlanList Component that can be used for both Mobile App and Website
const PlanList = ({ serviceType }) => {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedPlan, setExpandedPlan] = useState(null); // State to track expanded plan

    // Fetch the plans from Firebase (Mobile App or Website path based on serviceType)
    useEffect(() => {
        const plansRef = ref(database, `Plans/${serviceType}`);
        onValue(plansRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const fetchedPlans = Object.keys(data).map((key) => ({
                    id: key,
                    title: data[key].title,
                    ...data[key],
                }));
                setPlans(fetchedPlans);
            } else {
                setPlans([]);
            }
            setLoading(false);
        });
    }, [serviceType]);

    // Function to update Firebase with the new order after moving a plan
    const updateFirebaseOrder = async (updatedPlans) => {
        const updates = {};
        updatedPlans.forEach((plan, index) => {
            updates[`Plans/${serviceType}/${plan.id}/position`] = index + 1; // Update position
        });
        await update(ref(database), updates);
    };

    // Move a plan up in the list
    const movePlanUp = (index) => {
        if (index > 0) {
            const updatedPlans = [...plans];
            const planToMove = updatedPlans.splice(index, 1)[0]; // Remove the plan
            updatedPlans.splice(index - 1, 0, planToMove); // Insert it before the previous plan

            setPlans(updatedPlans);
            updateFirebaseOrder(updatedPlans);
        }
    };

    // Move a plan down in the list
    const movePlanDown = (index) => {
        if (index < plans.length - 1) {
            const updatedPlans = [...plans];
            const planToMove = updatedPlans.splice(index, 1)[0]; // Remove the plan
            updatedPlans.splice(index + 1, 0, planToMove); // Insert it after the next plan

            setPlans(updatedPlans);
            updateFirebaseOrder(updatedPlans);
        }
    };

    // Function to delete a plan from Firebase
    const deletePlan = async (id) => {
        const planRef = ref(database, `Plans/${serviceType}/${id}`);
        await remove(planRef);
        setPlans(plans.filter(plan => plan.id !== id)); // Remove plan from state
    };

    // Toggle plan details visibility
    const togglePlanDetails = (planId) => {
        setExpandedPlan(prev => (prev === planId ? null : planId));
    };

    if (loading) {
        return <p>Loading plans...</p>;
    }

    return (
        <div className="plan-list-container">
            <h2 className="plan-list-header">{serviceType} Plans</h2>
            {plans.length === 0 ? (
                <p>No plans available.</p>
            ) : (
                <ul>
                    {plans.map((plan, index) => (
                        <li key={plan.id} className="plan-item">
                            <div className="move-btns">
                                <button
                                    className="move-up-btn"
                                    onClick={() => movePlanUp(index)}
                                    disabled={index === 0}
                                >
                                    ▲
                                </button>
                                <button
                                    className="move-down-btn"
                                    onClick={() => movePlanDown(index)}
                                    disabled={index === plans.length - 1}
                                >
                                    ▼
                                </button>
                            </div>
                            <div className="plan-position">Position: {plan.position}</div>
                            <h3>{plan.title}</h3>

                            {/* Collapsible Button */}
                            <button className="collapsible-btn" onClick={() => togglePlanDetails(plan.id)}>
                                {expandedPlan === plan.id ? "Hide Details" : "Show Details"}
                            </button>

                            {/* Collapsible Content */}
                            <div className={`collapsible-content ${expandedPlan === plan.id ? 'active' : ''}`}>
                                <p><strong>Price:</strong> {plan.price}</p>
                                <ul>
                                    {plan.features && plan.features.map((feature, idx) => (
                                        <li key={idx} className="plan-feature-item">
                                            {feature.feature} - {feature.available ? "Available" : "Not Available"}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Delete Button */}
                            <button
                                className="delete-btn"
                                onClick={() => deletePlan(plan.id)}
                            >
                                <i className="fa fa-trash"></i> Delete
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PlanList;
