import React, { useState, useEffect } from 'react';
import orderService from '../lib/orderService';

function GetOrders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch orders from Firebase
    const fetchOrders = async () => {
        try {
            const fetchedOrders = await orderService.getOrders();
            setOrders(fetchedOrders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle order deletion
    const handleDeleteOrder = async (orderId) => {
        try {
            await orderService.deleteOrder(orderId); // Call the service to delete the order
            setOrders(orders.filter(order => order.id !== orderId)); // Remove the deleted order from the state
        } catch (error) {
            console.error('Error deleting order:', error);
            alert('Failed to delete order');
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div style={{ 
            padding: '20px', 
            borderRadius: '8px', 
            fontFamily: 'Arial, sans-serif' 
        }}>
            <h2 style={{ textAlign: 'center', color: '#4caf50' }}>Orders</h2>
            {loading ? (
                <div style={{ textAlign: 'center', fontSize: '20px', color: '#333' }}>
                    Loading orders...
                </div>
            ) : orders.length > 0 ? (
                orders.map((order) => (
                    <div key={order.id} style={{
                        marginBottom: '15px',
                        padding: '15px',
                        borderLeft: '4px solid #2196F3',
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        position: 'relative',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        overflow: 'hidden', // Prevent overflow
                        wordWrap: 'break-word', // Wrap text
                        whiteSpace: 'normal', // Allow text to wrap
                    }}>
                        <div style={{ marginBottom: '5px', fontWeight: 'bold', color: '#2196F3' }}>
                            Order ID: {order.id}
                        </div>
                        <div style={{ fontSize: '16px', color: '#333' }}>
                            <div><strong>Name:</strong> {order.name}</div>
                            <div><strong>Email:</strong> {order.email}</div>
                            <div><strong>Phone No:</strong> {order.mobile}</div>
                            <div><strong>Company:</strong> {order.company}</div>
                            <div><strong>Plan:</strong> {order.plan}</div>
                            <div><strong>Message:</strong> {order.message}</div>
                            <div><strong>Service Name:</strong> {order.serviceName}</div>
                            <div><strong>Timestamp:</strong> {new Date(order.timestamp).toLocaleString()}</div>
                        </div>
                        <button
                            onClick={() => handleDeleteOrder(order.id)}
                            style={{
                                backgroundColor: '#f44336',
                                color: 'white',
                                padding: '5px 10px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                position: 'absolute',
                                right: '10px',
                                top: '10px'
                            }}
                        >
                            Delete
                        </button>
                    </div>
                ))
            ) : (
                <div style={{ textAlign: 'center', fontSize: '20px', color: '#333' }}>
                    No orders found.
                </div>
            )}
        </div>
    );
}

export default GetOrders;
