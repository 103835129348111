import React, { useState, useEffect } from 'react';
import { ref, set, update } from "firebase/database";
import { database } from "../lib/firebase";
import './PlanForm.css';

const PlanForm = ({ existingPlan, onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        title: '',
        price: '',
        serviceType: 'Mobile App', // Default to "Mobile App" or "Website"
        features: [{ feature: '', available: false }],
        pop: false,
    });

    useEffect(() => {
        if (existingPlan) {
            setFormData(existingPlan);
        }
    }, [existingPlan]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFeatureChange = (index, value) => {
        const updatedFeatures = [...formData.features];
        updatedFeatures[index].feature = value;
        setFormData(prevData => ({
            ...prevData,
            features: updatedFeatures,
        }));
    };

    const handleFeatureToggle = (index) => {
        const updatedFeatures = [...formData.features];
        updatedFeatures[index].available = !updatedFeatures[index].available;
        setFormData(prevData => ({
            ...prevData,
            features: updatedFeatures,
        }));
    };

    const handlePopToggle = () => {
        setFormData(prevData => ({
            ...prevData,
            pop: !prevData.pop,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const planRef = ref(database, `Plans/${formData.serviceType}/${formData.title}`);
        if (existingPlan) {
            update(planRef, formData);
        } else {
            set(planRef, formData);
        }

        // Clear the form data after submission
        setFormData({
            title: '',
            price: '',
            serviceType: 'Mobile App',
            features: [{ feature: '', available: false }],
            pop: false,
        });

        onSubmit();
    };

    const addFeature = () => {
        setFormData(prevData => ({
            ...prevData,
            features: [...prevData.features, { feature: '', available: false }],
        }));
    };

    // Remove a feature by index
    const removeFeature = (index) => {
        const updatedFeatures = formData.features.filter((_, i) => i !== index);
        setFormData(prevData => ({
            ...prevData,
            features: updatedFeatures,
        }));
    };

    return (
        <form className='form-plan' onSubmit={handleSubmit}>
            <h2 className="plan-list-header">Add Plans</h2>

            <div>
                <label>Title</label>
                <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label>Price</label>
                <input
                    type="text"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label>Service Type</label>
                <select
                    name="serviceType"
                    value={formData.serviceType}
                    onChange={handleChange}
                    required
                >
                    <option value="Mobile App">Mobile App</option>
                    <option value="Website">Website</option>
                </select>
            </div>
            <div>
                <label>Features</label>
                {formData.features.map((featureObj, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={featureObj.feature}
                            onChange={(e) => handleFeatureChange(index, e.target.value)}
                            placeholder={`Feature ${index + 1}`}
                            style={{ marginRight: '10px' }}
                        />
                        <label>
                            Available
                            <input
                                type="checkbox"
                                checked={featureObj.available}
                                onChange={() => handleFeatureToggle(index)}
                            />
                        </label>
                        <button
                            type="button"
                            className="remove-feature-btn" // Added class here
                            onClick={() => removeFeature(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={addFeature} style={{ marginTop: '10px' }}>Add Feature</button>
            </div>
            <div>
                <label>
                    Pop
                    <input
                        type="checkbox"
                        checked={formData.pop}
                        onChange={handlePopToggle}
                    />
                </label>
            </div>
            <button type="submit">Submit</button>
            <button type="button" onClick={onCancel}>Cancel</button>
        </form>
    );
};

export default PlanForm;
