import React from 'react';
import PlanList from './PlanList'; // Import the PlanList component

// PlanListButton Component that provides separate options for Mobile App and Website
const PlanListButton = () => {
    return (
        <div>
                <PlanList serviceType="Mobile App" />
                <PlanList serviceType="Website" />
        </div>
    );
};

export default PlanListButton;
