import React from 'react';

function AboutUs() {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.6' }}>

            {/* Hero Section */}
            <section style={{ backgroundColor: '#f0f4f8', padding: '80px 20px', textAlign: 'center' }}>
                <h1 style={{ fontSize: '3rem', color: '#2c3e50', fontWeight: 'bold' }}>About Us</h1>
                <p style={{ fontSize: '1.2rem', color: '#7f8c8d', maxWidth: '700px', margin: '0 auto' }}>
                    We're dedicated to providing top-notch services that empower individuals and businesses to reach their full potential. Here’s our story and what drives us.
                </p>
                <img 
                    src="https://placehold.jp/1000x300.png" 
                    alt="Our Team" 
                    style={{ width: '100%', borderRadius: '12px', marginTop: '30px', maxWidth: '1200px' }} 
                />
            </section>

            {/* Mission and Vision with Overlapping Layout */}
            <section style={{ display: 'flex', padding: '80px 20px', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '300px', margin: '20px', position: 'relative', zIndex: '2' }}>
                    <h2 style={{ fontSize: '2.5rem', color: '#2c3e50', fontWeight: 'bold' }}>Our Mission</h2>
                    <p style={{ color: '#7f8c8d', fontSize: '1rem', lineHeight: '1.8' }}>
                        Our mission is to transform ideas into impactful solutions that benefit individuals and businesses alike.
                    </p>
                </div>
                <img 
                    src="https://placehold.jp/300x300.png" 
                    alt="Mission" 
                    style={{ width: '300px', borderRadius: '50%', margin: '20px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)' }}
                />
                
                <img 
                    src="https://placehold.jp/300x300.png" 
                    alt="Vision" 
                    style={{ width: '300px', borderRadius: '50%', margin: '20px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)' }}
                />
                <div style={{ width: '300px', margin: '20px', position: 'relative', zIndex: '2' }}>
                    <h2 style={{ fontSize: '2.5rem', color: '#2c3e50', fontWeight: 'bold' }}>Our Vision</h2>
                    <p style={{ color: '#7f8c8d', fontSize: '1rem', lineHeight: '1.8' }}>
                        We envision a world where our services create value and opportunities for all.
                    </p>
                </div>
            </section>

            {/* Team Section with Grid Layout */}
            <section style={{ backgroundColor: '#f9fafc', padding: '80px 20px', textAlign: 'center' }}>
                <h2 style={{ fontSize: '2.5rem', color: '#2c3e50', fontWeight: 'bold', marginBottom: '40px' }}>Meet Our Team</h2>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '20px', maxWidth: '1000px', margin: '0 auto' }}>
                    {['John Doe', 'Jane Smith', 'Alice Johnson', 'Mark Wilson'].map((name, index) => (
                        <div key={index} style={{ 
                            backgroundColor: '#fff', 
                            borderRadius: '12px', 
                            padding: '20px', 
                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', 
                            textAlign: 'center' 
                        }}>
                            <img 
                                src="https://placehold.jp/150x150.png" 
                                alt={`${name}`} 
                                style={{ width: '100%', borderRadius: '50%', marginBottom: '15px' }}
                            />
                            <h4 style={{ fontSize: '1.2rem', color: '#2c3e50', marginBottom: '8px' }}>{name}</h4>
                            <p style={{ color: '#7f8c8d' }}>
                                {index === 0 ? 'CEO & Founder' : index === 1 ? 'CTO' : index === 2 ? 'Product Manager' : 'Marketing Lead'}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Values Section with Icons and Columns */}
            <section style={{ padding: '80px 20px', backgroundColor: '#f0f4f8', textAlign: 'center' }}>
                <h2 style={{ fontSize: '2.5rem', color: '#2c3e50', fontWeight: 'bold', marginBottom: '40px' }}>Our Values</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', maxWidth: '1000px', margin: '0 auto' }}>
                    {[
                        { title: 'Integrity', icon: '🛡️', text: 'We uphold integrity in all that we do.' },
                        { title: 'Innovation', icon: '💡', text: 'We drive innovation to create impactful solutions.' },
                        { title: 'Excellence', icon: '🏆', text: 'We strive for excellence in every project.' },
                        { title: 'Collaboration', icon: '🤝', text: 'We believe in the power of teamwork.' }
                    ].map((value, index) => (
                        <div key={index} style={{ 
                            width: '220px', 
                            textAlign: 'center', 
                            margin: '10px', 
                            padding: '20px', 
                            backgroundColor: '#fff', 
                            borderRadius: '12px', 
                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' 
                        }}>
                            <div style={{ fontSize: '2.5rem', marginBottom: '15px' }}>{value.icon}</div>
                            <h4 style={{ fontSize: '1.5rem', color: '#2c3e50', marginBottom: '10px' }}>{value.title}</h4>
                            <p style={{ fontSize: '1rem', color: '#7f8c8d' }}>{value.text}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer Contact Section */}
            <section style={{ backgroundColor: '#2c3e50', color: '#fff', padding: '60px 20px', textAlign: 'center' }}>
                <h3 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px' }}>Let's Connect</h3>
                <p style={{ fontSize: '1rem', color: '#ecf0f1', maxWidth: '600px', margin: '0 auto', marginBottom: '20px' }}>
                    Interested in learning more? Reach out to us for any inquiries or to join our journey.
                </p>
                <a href="mailto:info@example.com" style={{ color: '#f39c12', fontSize: '1.2rem', textDecoration: 'none' }}>info@example.com</a>
                <div style={{ fontSize: '1.5rem', marginTop: '20px' }}>
                    <a href="https://facebook.com" style={{ margin: '0 10px', color: '#f39c12', textDecoration: 'none' }}>Facebook</a>
                    <a href="https://twitter.com" style={{ margin: '0 10px', color: '#f39c12', textDecoration: 'none' }}>Twitter</a>
                    <a href="https://linkedin.com" style={{ margin: '0 10px', color: '#f39c12', textDecoration: 'none' }}>LinkedIn</a>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
