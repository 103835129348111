import React, { useState, useMemo } from 'react';
import '../components/Console.css';
import { GetHomeFAQs, GetAppFAQs, GetWebFAQs } from '../components/AdminFAQ';
import GetOrders from '../lib/getOrders';
import { login, getEmailByUsername } from "../lib/firebase";
import { Signup } from '../lib/admineLogin';
import GoogleForm from '../components/GoogleFormEmbed';
import PlanForm from '../components/PlanForm';
import PlanListButton from '../components/PlanListButton';

function AdminConsole() {
    const [selectedSection, setSelectedSection] = useState('Welcome');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    // Handle section change and close sidebar
    const handleSectionChange = (section) => {
        setSelectedSection(section);
        setIsSidebarVisible(false);
    };

    // Handle login
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const email = await getEmailByUsername(username);
            if (email) {
                await login(email, password);
                handleSectionChange('Orders');
            } else {
                alert("Username not found. Please check your username.");
            }
        } catch (error) {
            alert("Error logging in: " + error.message);
        }
    };

    // Toggle sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible(prev => !prev);
    };

    // Handle plan form submission
    const handlePlanFormSubmit = () => {
        // Logic for handling plan submission (like refreshing plans)
        // handleSectionChange('Orders'); // Navigate to Orders after submission
    };

    // Define styles outside render method for better performance
    const styles = useMemo(() => ({
        body: {
            margin: 0,
            fontFamily: "Arial, sans-serif",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        container: {
            maxWidth: "400px",
            width: "90%",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        },
        heading: {
            textAlign: "center",
            fontSize: "24px",
            color: "#333",
            marginBottom: "20px",
        },
        input: {
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxSizing: "border-box",
            transition: "border 0.3s",
        },
        button: {
            padding: "10px",
            width: "100%",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background-color 0.3s",
        },
    }), []);

    // Render content based on selected section
    const renderContent = () => {
        switch (selectedSection) {
            case 'GoogleFormEmbed':
                return <GoogleForm />;
            case 'AddPlans':
                return (
                    <div>
                        <PlanListButton />
                        <PlanForm
                            existingPlan={null} // Null for new plan
                            onSubmit={handlePlanFormSubmit}
                        />
                    </div>
                );
            case 'FAQs':
                return (
                    <div>
                        <h2 style={{ textAlign: 'center', color: '#4caf50', fontSize: '24px' }}>FAQ Management</h2>
                        <GetHomeFAQs />
                        <GetAppFAQs />
                        <GetWebFAQs />
                    </div>
                );
            case 'Orders':
                return <GetOrders />;
            case 'AddNewMember':
                return <Signup />;
            default:
                return (
                    <div style={styles.body}>
                        <div style={styles.container}>
                            <h2 style={styles.heading}>Login</h2>
                            <form onSubmit={handleLogin}>
                                <input 
                                    type="text" 
                                    placeholder="Username" 
                                    value={username} 
                                    onChange={(e) => setUsername(e.target.value)} 
                                    required 
                                    style={styles.input} 
                                />
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required 
                                    style={styles.input} 
                                />
                                <button type="submit" style={styles.button}>Login</button>
                            </form>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="console-container">
            <button className="menu-toggle-button" onClick={toggleSidebar}>
                ☰
            </button>
            <div className={`left-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div>Admin Console</div>
                <div className='left-sidebar-options'>
                    <div onClick={() => handleSectionChange('Orders')}>Orders</div>
                    <div onClick={() => handleSectionChange('FAQs')}>FAQs</div>
                    <div onClick={() => handleSectionChange('AddPlans')}>Add Plans</div>
                    <div onClick={() => handleSectionChange('GoogleFormEmbed')}>Google Form</div>
                    <div onClick={() => handleSectionChange('AddNewMember')}>Add New User</div>
                </div>
            </div>
            <div className="right-sidebar">
                {renderContent()}
            </div>
        </div>
    );
}

export default AdminConsole;
