import React, { useState, useRef, useEffect } from 'react';
import NavigationBar from '../components/NavBar';
import '../components/AppService.css';
import { database } from '../lib/firebase';
import { ref, onValue, push, set } from 'firebase/database';
import faqService from '../lib/getFAQ';
import '../components/loading.css'
export default function ApplicationService() {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [plans, setPlans] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        plan: '',
        mobile: '',
        company: '',
    });
    const contactSectionRef = useRef(null);

    const toggleQuestion = (index) => {
        setSelectedQuestion(selectedQuestion === index ? null : index);
    };

    useEffect(() => {
        async function fetchFAQ() {
            const fetchedFaqs = await faqService.getAppFAQ();
            setFaqs(fetchedFaqs);
        }

        function fetchPlans() {
            const plansRef = ref(database, 'Plans/Mobile App');
            onValue(plansRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const fetchedPlans = Object.keys(data).map((key) => ({
                        ...data[key],
                        key,
                    }));

                    // Sort plans by position
                    fetchedPlans.sort((a, b) => a.position - b.position);

                    setPlans(fetchedPlans);
                }
            });
        }

        fetchFAQ();
        fetchPlans();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const serviceName = 'Mobile Application Service';
        const messageRef = push(ref(database, 'Orders'));
        set(messageRef, { ...formData, serviceName, timestamp: new Date().toISOString() });
        setFormData({
            name: '',
            email: '',
            message: '',
            plan: '',
            mobile: '',
            company: '',
        });
    };

    const handleChoosePlan = (planTitle) => {
        setFormData({
            ...formData,
            plan: planTitle,
        });
        contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="app-service-container">
            <NavigationBar position_absolute={false} />
            <div className="top-text" style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
                Our Pricing Plans
            </div>
            <div className="plans-container">
                {plans?.length > 0 ? (
                    plans.map((plan) => (
                        <div className={`plan-card ${plan.pop ? 'pop' : ''}`} key={plan.key}>
                            <div className="plan-title">{plan.title}</div>
                            <p>Rs.{plan.price}</p>
                            <hr />
                            <ul>
                                {plan.features?.map((feature, index) => (
                                    <li key={index}>
                                        <span className="icon">
                                            {typeof feature === 'string' ? '✔️' : feature.available ? '✔️' : '❌'}
                                        </span>
                                        {typeof feature === 'string' ? feature : feature.feature}
                                    </li>
                                ))}
                            </ul>
                            <button className="choose-plan-button" onClick={() => handleChoosePlan(plan.title)}>
                                Choose Plan
                            </button>
                        </div>
                    ))
                ) : (
                    <span class="loader"></span>
                )}
            </div>
            <div className="faq-section">
                <div className="faq-top-text">FAQs</div>
                <div className="faq-list">
                    {faqs?.length > 0 ? (
                        faqs.map((faq, index) => (
                            <div key={index} className={`faq-item ${selectedQuestion === index ? 'active' : ''}`}>
                                <div className="faq-question" onClick={() => toggleQuestion(index)}>
                                    {faq.question}
                                </div>
                                <div className="faq-answer">
                                    {selectedQuestion === index && faq.answer}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No FAQs</p>
                    )}
                </div>
            </div>
            <div className="top-text" style={{ marginTop: '60px', textAlign: 'center' }}>Contact Us</div>
            <div className="contact-content" ref={contactSectionRef}>
                <div className="contact-sections">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number</label>
                            <input
                                type="text"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="company">Company Name</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="plan">Choose Plan</label>
                            <select
                                id="plan"
                                name="plan"
                                value={formData.plan}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a plan</option>
                                {plans?.map((plan) => (
                                    <option key={plan.key} value={plan.title}>
                                        {plan.title} - {plan.price}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button className="form-btn" type="submit">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
